import { FC } from 'react'
import { useI18nContext } from '@/i18n/i18n-react'
import clsx from 'clsx'
import Link from 'next/link'

type FooterLandingProps = {
    visible?: boolean
}

const FooterLanding: FC<FooterLandingProps> = ({ visible = false }) => {    
    const { LL } = useI18nContext()
    
    return (
        <section id="from-now" className="from-now">
            <div className="from-now--wrapper">
                <h5 className={clsx('text', !visible && 'inview--fade-in')}>{LL.landing.footer.fromNow()}<br/>{LL.landing.footer.youCreate()}</h5>

                <div className={clsx('navigator', !visible && 'inview--fade-in')}>
                    <div className="item item-01">
                        <a className="mail" href="mailto:info@ignion.io">info@ignion.io</a>
                        <span>{LL.generic.footer.copyright()}</span>
                    </div>
                    <div className="item item-02">{LL.dashboard.empty.ideasAlive()}</div>
                    <div className="item item-03">
                        <div className="subitem"><a href="mailto:info@ignion.io">info@ignion.io</a></div>
                        <div className="subitem"><Link href="/cookies-policy">{LL.generic.footer.cookiesPolicy()}</Link></div>
                        <div className="subitem"><a href="https://ignion.io/privacy-policy/" target="_blank" rel='noreferrer'>{LL.generic.footer.privacy()}</a></div>
                        <div className="subitem"><a href="https://ignion.io/terms-of-use/" target="_blank" rel='noreferrer'>{LL.generic.footer.terms()}</a></div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default FooterLanding