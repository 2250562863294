'use client'

import Head from 'next/head'
import ToastContainer from '@/components/ToastService'
import { ChildContainerProps } from '@/types/types'

const LandingLayout = ({ children }: ChildContainerProps) => {
  
  return (
    <>
      <Head>
          <title>Oxion | Your ideas Alive</title>
      </Head>
      <ToastContainer />
      <div className='landing'>
        { children }
      </div>
    </>
  );
};

export default LandingLayout;
