import { FC, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { UserTopBarButton } from '@/components/account/UserTopBarButton'
import { blockScroll, unBlockScroll } from '@/lib/utils'
import clsx from 'clsx'

export type HeaderSubOptions = {
    label: string
    href: string
    active: boolean
}

type HeaderLandingProps = {
    cleanHeader?: boolean
    options: HeaderSubOptions[]
    ctaText?: string
    ctaLink?: string
}

const HeaderLanding: FC<HeaderLandingProps> = ({ cleanHeader = false, options, ctaText = 'Try for free', ctaLink = '/signup' }) => {

    const router = useRouter()
    const { user } = useAuthenticator(context => [context.route, context.user, context.authStatus])

    const signInRoute = ctaText === 'Try for free' ? '/signin' : '/signin-corp'

    const isMobile = () => window.innerWidth <= 576

    const toggleMenu = () => {
        const header = document.querySelector('.landing-oxion header') as HTMLElement

        if (header) {
            if ( !header.classList.contains('opened') ) {
                header.classList.add('opened')
                header.dataset.opened = '1'
                header.classList.remove('closed')

                if ( isMobile() ) {
                    blockScroll()
                }

            }
            else {
                header.classList.remove('opened')
                header.dataset.opened = '1'
                unBlockScroll()

                if ( !( isMobile() ) ) {
                    header.classList.add('closed')
                }
            }
        }
    }

    const initLanding = () => {
        const navMobile = document.querySelector('nav.mobile') as HTMLElement
        navMobile.addEventListener('click', (e) => {
            e.preventDefault()
            toggleMenu()
        })
    }

    const checkVisibility = ( header:HTMLElement, elementId:string ) => {
        const element = document.querySelector(elementId)
        const position = element?.getBoundingClientRect()

        // If position.top is lower than 0, the scroll is under the element
        if (position && position.top < 0) {
            if ( !header.classList.contains('opened') ) {
                header.classList.add('closed')
                header.classList.add('hover')
            }
            else {
                header.dataset.opened = ( parseInt(header.dataset.opened ?? '') + 1 ).toString()
                if ( parseInt(header.dataset.opened) > 25 ) {
                    header.classList.remove('opened')
                }
            }

        } else {
            header.classList.remove('closed')
            header.classList.remove('hover')
        }
    }

    const controlMenu = () => {
        const header = document.querySelector('.landing-oxion header') as HTMLElement
        
        if (cleanHeader && header) {
            if ( !header.classList.contains('opened') ) {
                header.classList.add('closed')
                header.classList.add('hover')
            }
            else {
                header.dataset.opened = ( parseInt(header.dataset.opened ?? '') + 1 ).toString()
                if ( parseInt(header.dataset.opened) > 15 ) {
                    header.classList.remove('opened')
                }
            }
        } else if (header) {    
                checkVisibility( header, '.hideHeader' )
        }
    }

    useEffect(() => {
        initLanding()
      }, []);

    useEffect(() => {
        const handleScrollHeader = () => {            
            controlMenu()
        };

        window.addEventListener('scroll', handleScrollHeader)

        return () => {
            window.removeEventListener('scroll', handleScrollHeader)
        };
    }, []);
    
  return (
    <header className={clsx(cleanHeader && 'active closed hover')}>
        <div className="logo">
            <Link href="/" aria-label='Oxion'>
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="23" viewBox="0 0 72 23" fill="none">
                <path d="M7.76194 6.28455C2.71934 6.28455 0 10.4705 0 14.4093C0 18.3347 2.71934 22.5037 7.76194 22.5037C12.8212 22.5037 15.5505 18.3347 15.5505 14.4093C15.5505 10.4705 12.8212 6.28455 7.76194 6.28455ZM7.76194 19.9658C4.7963 19.9658 2.72933 17.6816 2.72933 14.4093C2.72933 11.1202 4.79962 8.82587 7.76194 8.82587C10.7442 8.82587 12.8245 11.1236 12.8245 14.4093C12.8245 17.6951 10.7442 19.9658 7.76194 19.9658Z" fill="#F2F2F2"/>
                <path d="M31.0854 6.63647H27.7403L23.4233 12.176L19.0797 6.63647H15.7046L21.7658 14.3214L15.5615 22.1552H18.9066L23.3934 16.4092L27.8834 22.1552H31.2885L25.0243 14.2909L31.0854 6.63647Z" fill="#F2F2F2"/>
                <path d="M33.1309 9.05936H35.5407V22.1552H38.2367V6.63647H33.1309V9.05936Z" fill="#F2F2F2"/>
                <path d="M36.9015 0.0443995C36.1825 0.0443995 35.5501 0.443703 35.2572 1.0765C35.154 1.2863 35.1074 1.50287 35.1074 1.78035V1.89541C35.1074 2.90043 35.863 3.63136 36.9015 3.63136C37.9399 3.63136 38.6955 2.89028 38.6955 1.86495C38.6955 0.826086 37.9233 0.0410156 36.9015 0.0410156V0.0443995Z" fill="#F2F2F2"/>
                <path d="M48.6096 6.28455C43.5703 6.28455 40.8477 10.4705 40.8477 14.4093C40.8477 18.3347 43.567 22.5037 48.6096 22.5037C53.6688 22.5037 56.3982 18.3347 56.3982 14.4093C56.3982 10.4705 53.6688 6.28455 48.6096 6.28455ZM48.6096 19.9658C45.6439 19.9658 43.577 17.6816 43.577 14.4093C43.577 11.1202 45.6473 8.82587 48.6096 8.82587C51.5919 8.82587 53.6722 11.1236 53.6722 14.4093C53.6722 17.6816 51.5919 19.9658 48.6096 19.9658Z" fill="#F2F2F2"/>
                <path d="M66.1322 6.28748C64.0553 6.28748 62.5508 7.17068 61.6421 8.19939V6.63602H59.0293V22.1547H61.7287V12.9809C61.7287 10.382 63.2831 8.76789 65.786 8.76789C68.1193 8.76789 69.3009 10.1079 69.3009 12.7474V22.1547H72.0003V12.3413C72.0003 8.66299 69.697 6.28748 66.1322 6.28748Z" fill="#F2F2F2"/>
            </svg>
            </Link>
        </div>

        <nav className="desktop">
            <ul>
                {
                    !cleanHeader && 
                    <>
                        {
                            options.map((option) => <li key={`desktop-${option.label}`} className={clsx('link', option.active && 'active')}><a href={option.href}>{option.label}</a></li>)
                        }
                    </>
                }
                {
                    user ?
                    <li>
                        <UserTopBarButton label={`${user?.attributes?.given_name} ${user?.attributes?.family_name}`} onClick={() => {router.push('/dashboard', undefined, { shallow: true })}} />
                    </li>
                    : <>
                        <li className="login"><a href={signInRoute}>Login</a></li>
                        <li className="button"><a href={ctaLink} id="btTryForFree">{ctaText}</a></li>
                    </>
                }
            </ul>
        </nav>

        <div className="menu-ham--container">
            <span>Menu</span>
            <button className="menu-ham" onClick={toggleMenu} aria-label='Menu'>
                <svg className="close" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.9999 16.3527L33.7053 33.0581" stroke="#0000FF" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                    <path d="M16.9999 33.0582L33.7053 16.3528" stroke="#0000FF" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                </svg>

                <svg className="open" xmlns="http://www.w3.org/2000/svg" width="26" height="11" viewBox="0 0 26 11" fill="none">
                    <path d="M1 1H24.625" stroke="#0000FF" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                    <path d="M13.1064 10L24.6251 10" stroke="#0000FF" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                </svg>
            </button>
        </div>

        <nav className="mobile">
            <ul>
                {
                    !cleanHeader && 
                    <>
                        {
                            options.map((option) => <li key={`mobile-${option.label}`} className={clsx('link', option.active && 'active')}><a href={option.href}>{option.label}</a></li>)
                        }
                    </>
                }
                {
                    user ?
                    <li>
                        <UserTopBarButton label={`${user?.attributes?.given_name} ${user?.attributes?.family_name}`} onClick={() => { router.push('/dashboard', undefined, { shallow: true })} } />
                    </li>
                    : <>
                        <li className="button"><a href={ctaLink} id="btTryForFreeMobile">{ctaText}</a></li>
                        <li className="login"><a href={signInRoute}>or Login</a></li>
                    </>
                }
            </ul>

            <div className="box">
                <h5 className="title">Any suggestions?</h5>
                <p className="text">Let’s connect and share them with us.</p>
                <a href="mailto:info@ignion.io">info@ignion.io</a>
            </div>
        </nav>
    </header>
  )
}

export default HeaderLanding